/**
 * Layout - Print Media
 * --------------------
 * Print view overrides for layout components.
 * @charset "utf-8";
 */

/**
 * These are things we want to hide when printing
 */
#PageHeader,
#PageBreadcrumbs,
#BodyRightLane,
#PropViewBg,
#PageFooter,
#bgstretcher,
#directionsTeaser,
.secondaryLink,
#imageSash
{ 
   display: none; 
}

/* PDP */
#PageBody.propertyDetails .propListingProvider {
   position: relative;
   clear: both;
}   